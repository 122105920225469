import React, { useEffect, useState } from "react";
import BasicSlider from "./app.basicslider"; // Import your existing BasicSlider component

let bookID: any; // Hardcoded bookID
let tuneNumber: any; // Hardcoded tuneNumber

function App() {
  const [numPrompts, setNumPrompts] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({
    bookID: '',
    tuneNumber: ''
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    bookID = urlParams.get('book_id');
    tuneNumber = urlParams.get('tune_id');
    console.log("url params", urlParams);
    if (bookID && tuneNumber) {
      setParams({
        bookID,
        tuneNumber
      });
      console.log("bookID", bookID);
      console.log("tuneNumber", tuneNumber);
    } else {
      console.log("unable to access params");
    }

    const fetchNumPrompts = async () => {
      try {
        //const response = await fetch(`https://giftomagic.com/api/num-prompts?book_id=${bookID}`);
        //const data = await response.json();
        //console.log("data & response", data)
        setNumPrompts(4);
      } catch (error) {
        console.error("Error fetching number of prompts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNumPrompts();

    // Function to handle messages from child components
    const handleMessage = (event: MessageEvent) => {
      // Make sure the message contains height data
      if (event.data && event.data.height) {
        // Send the height to the parent window (the iframe container)
        if (window.parent && window.parent !== window) {
          window.parent.postMessage({
            height: event.data.height
          }, '*');
          console.log("App forwarded height to parent:", event.data.height);
        }
      }
    };

    // Add message listener
    window.addEventListener('message', handleMessage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    
  }, []);

  if (loading) return <p className="text-center text-lg font-semibold">Loading sliders...</p>;

  if (numPrompts === null || numPrompts === 0)
    return <p className="text-center text-lg font-semibold">No prompts found.</p>;

  return (
    <div className="flex flex-col gap-6">
      {Array.from({ length: numPrompts }, (_, promptNumber) => (
        <BasicSlider key={promptNumber} bookID={bookID} tuneNumber={tuneNumber} promptNumber={promptNumber.toString()} />
      ))}
    </div>
  );
}

// Add this to your giftomagic.com site
function sendHeightToParent() {
  // Get the document height
  const height = document.body.scrollHeight;
  
  // Send message to parent window
  if (window.parent) {
    window.parent.postMessage({
      height: height
    }, '*'); // In production, replace '*' with the parent domain for security
  }
}

// Call immediately after page loads
window.addEventListener('load', sendHeightToParent);

// Also call when content changes or when window resizes
window.addEventListener('resize', sendHeightToParent);
// Call this function whenever your content changes
// For example, after AJAX loads or content updates

export default App;
