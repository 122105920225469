import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface ImageSliderProps {
  bookID: string;
  tuneNumber: string;
  promptNumber: string;
}

export default function ImageSlider({ bookID, tuneNumber, promptNumber }: ImageSliderProps) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const tuneBookIdPromptNumber = `${tuneNumber}_${bookID}_${promptNumber}`; // Dynamically generated
  const maxRetries = 6;
  const [retryCount, setRetryCount] = useState(0);
  const hasGeneratedImages = useRef(false);
  const sliderRef = useRef<Slider | null>(null);

  // Simple function to send height to parent
  const sendHeightToParent = () => {
    const height = document.body.scrollHeight;
    if (window.parent && window.parent !== window) {
      window.parent.postMessage({
        height: height
      }, '*');
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        console.log(`Fetching images... (Attempt ${retryCount + 1})`);
        const response = await fetch(
          `https://giftomagic.com/api/images?tune_book_id_prompt_number=${tuneBookIdPromptNumber}`
        );
        const data = await response.json();

        if (data.images) {
          console.log("Setting images for prompt", Number(promptNumber)+1)
          setImages(data.images);
          setLoading(false);
        } else if (!hasGeneratedImages.current) {
          hasGeneratedImages.current = true;
          console.warn("No images found, attempting to generate them...");

          const promptResponse = await fetch(
            `https://giftomagic.com/api/prompt?book_id=${bookID}&prompt_number=${promptNumber}`
          );
          const promptData = await promptResponse.json();

          if (!promptData.prompt) {
            console.error("No prompt found in API response.");
            return;
          }

          console.log("Fetched prompt:", promptData.prompt);

          await fetch("https://giftomagic.com/api/generate-images", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              prompt: promptData.prompt,
              tune_number: tuneNumber,
              book_id: bookID,
              prompt_number: promptNumber,
            }),
          });
        }
        // Set retry timer only if we don't have images yet (whether we just generated or not)
        if (!data.images) {
          console.log("Image generation requested. Waiting x minutes before retrying... prompt is", Number(promptNumber)+1 );
          setTimeout(() => setRetryCount((prev) => prev + 1), 60000);
        }
      } catch (error) {
        console.log(JSON.stringify({ message: "Error fetching images", error: error }));
      } finally {
        // do nothing
        const a = 1
      }
    };

    if (retryCount < maxRetries) {
      fetchImages();
    } else {
      console.error("Max retries reached. Images not found.");
      setLoading(false);
    }
  }, [retryCount, tuneBookIdPromptNumber, bookID, promptNumber, tuneNumber]);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    customPaging: (i: number) => (
      <button
        className="w-3 h-3 bg-gray-500 rounded-full focus:outline-none"
        onClick={() => sliderRef.current?.slickGoTo(i)}
      />
    ),
    appendDots: (dots: string | number | bigint | boolean | React.ReactElement<unknown, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Promise<string | number | bigint | boolean | React.ReactPortal | React.ReactElement<unknown, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined> | null | undefined) => (
      <div style={{ position: "absolute", bottom: "-30px", width: "100%" }}>
        <ul className="flex justify-center gap-2">{dots}</ul>
      </div>
    ),
    className: "w-full relative",
  };

  return (
    <div className="relative flex justify-center">
      {loading ? (
        <p className="text-center text-lg font-semibold">Working to build your amazing book...</p>
      ) : images.length > 0 ? (
        <>
          <div className="inline-block" style={{ maxWidth: "1024px" }}>
            <Slider ref={sliderRef} {...settings}>
              {images.map((img, idx) => (
                <div key={idx} className="flex justify-center">
                  <img 
                    src={img} 
                    alt={`Generated ${idx}`} 
                    className="rounded-lg shadow-lg w-full"
                    onLoad={idx === 0 ? sendHeightToParent : undefined} 
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
      ) : (
        <p className="text-center text-lg font-semibold">No images found. :sad</p>
      )}
    </div>
  );
}
